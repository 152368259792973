import React from 'react';

const Services = () => {
  const services = [
    { title: "Custom Software Development", description: "We build powerful, scalable solutions designed specifically for your business, using the latest technologies to deliver robust, efficient, and user-friendly products." },
    { title: "Web and Mobile App Development", description: "We create engaging web and mobile applications that offer a flawless user experience, focusing on speed, reliability, and scalability." },
    { title: "Enterprise Solutions", description: "Our enterprise solutions streamline processes, integrate seamlessly with existing systems, and help you make data-driven decisions that improve productivity." },
    { title: "Cloud & DevOps", description: "We help you build, deploy, and manage applications in the cloud with ease, ensuring your software is optimized for performance, security, and scalability." },
    { title: "AI & Machine Learning", description: "We build intelligent systems that automate repetitive tasks, analyze data, and deliver insights that drive smarter decisions for your business." }
  ];

  return (
    <section id="services" className="services section">
      <div className="container">
        <h2>Our Services</h2>
        <div className="service-grid">
          {services.map((service, index) => (
            <div key={index} className="service-card">
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;