import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        'service_ldja8iv',
        'template_0cdxziw',
        formData,
        'KVn_SiIQhaWJxOPwy' // public-key
      )
      .then(
        (result) => {
          console.log('Email successfully sent!', result.text);
          alert('Message sent successfully!');
        },
        (error) => {
          console.log('Failed to send the email.', error.text);
          alert('Failed to send the message, please try again.');
        }
      );

    // Reset form after submission
    setFormData({ name: '', email: '', contact: '', message: '' });
  };

  return (
    <section id="contact" className="contact section">
      <div className="container">
        <div className="contact-content">
            <h2>Contact Us</h2>
            <form onSubmit={handleSubmit}>
            <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Your Name"
                required
            />
            <input
                type="contact"
                name="contact"
                value={formData.contact}
                onChange={handleChange}
                placeholder="Your Contact"
                required
            />
            <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Your Email"
                required
            />
            <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Your Message"
                required
            ></textarea>
            <button type="submit">Send Message</button>
            </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
