import React from 'react';

const About = () => {
  const aboutCards = [
    {
      title: "Problem Solvers",
      description: "No challenge is too complex for us. We dive deep into understanding your issues,  and create smart, tailored solutions that not only solve your problems but drive meaningful results for your business."
    },
    {
      title: "Growth Focused",
      description: "We don't just build software; we partner with you to help your business thrive. Our solutions are strategically designed with your long-term success and scalability in mind, ensuring sustainable growth."
    },
    {
      title: "Client-Centered Approach",
      description: "We believe in close collaboration. Your feedback is essential throughout the process, ensuring that the final product meets and exceeds your expectations."
    },
    {
      title: "Cutting-Edge Technologies",
      description: "Our developers stay up-to-date with the latest trends and technologies, giving you access to solutions that are innovative and future-proof."
    }
  ];

  return (
    <section id="about" className="about section">
      <div className="container">
        <h2 className="fade-in-up">Who We Are</h2>
        <p className="fade-in-up">At Dev Async, we're more than just software developers. We're problem solvers. With a passion for innovation, we specialize in turning your complex challenges into seamless digital solutions that drive growth and efficiency for your business.</p>
        <div className="about-grid">
          {aboutCards.map((card, index) => (
            <div key={index} className="about-card fade-in-up">
              <h3>{card.title}</h3>
              <p>{card.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default About;