import React from 'react';

const Portfolio = () => {
  const projects = [
    { name: "Healthcare Management System", description: "Developed a comprehensive Electronic Medical Records (EMR) system to streamline scheduling, patient data management, and enhance overall healthcare efficiency." },
    { name: "Game Backend Engine", description: "Built a robust, scalable backend engine to support real-time multiplayer gameplay with seamless communication and high performance." },
    { name: "E-commerce Platform", description: "Created a fully-featured ecommerce platform with advanced product management, secure payment integration, and a user-friendly interface to enhance the shopping experience." },
    { name: "AI-Powered Chatbot", description: "Designed an AI-driven intelligent chatbot that automates customer service, offering quick, personalized responses and improving user satisfaction, reducing support tickets." },
    { name: "Data Analytics", description: "Developed a powerful data analytics solution to help businesses gain insights from large datasets, driving data-driven decisions and strategies." },
    { name: "Roadside Assistance App", description: "Created an on-demand app offering towing, flat tire repair, fuel delivery, and other vehicle recovery services for quick and reliable roadside support." }
  ];

  return (
    <section id="portfolio" className="portfolio section">
      <div className="container">
        <h2>Our Portfolio</h2>
        <div className="portfolio-grid">
          {projects.map((project, index) => (
            <div key={index} className="portfolio-item">
              <h3>{project.name}</h3>
              <p>{project.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;