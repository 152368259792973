import React from 'react';

const Hero = () => {
  return (
    <section id="home" className="hero section">
      <div className="container">
        <h2 className="fade-in-up">Transcending Beyond Horizons</h2>
        <p className="fade-in-up">DevAsync: Your partner in innovative software solutions</p>
        <a href="#contact" className="cta-button fade-in-up">Get Started</a>
      </div>
    </section>
  );
};

export default Hero;